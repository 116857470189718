:root {
  --primary-color: #ab8bf8;
  --democracy-color: #f8ab8b;
  --climate-color: #8bf8ab;
  --security-color: #8b8bf8;
  --economy-color: #f8f88b;
}

.track-details {
  opacity: 0;
  max-height: 0;
  overflow: hidden;
}

.track-details.active {
  opacity: 1;
  max-height: 1200px;
}

body {
  padding: 20px;
  font-family: "degular", sans-serif;
  font-weight: 400;
  font-style: normal;
  height: auto;
  transition: background-color 0.5s ease; 
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
  overflow: hidden;
}

path {
    transition: fill 0.5s ease;
}

#container {
  max-width: 1600px;
  margin: 0 auto;
  height: auto;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.title {
  font-family: "antique-olive-nord", sans-serif;
  font-weight: 400;
  font-style: normal;
  text-transform: uppercase;
  font-size: 24px;
}

.menu {
  width: 400px;
  display: flex;
  justify-content: space-between;
}

.menu-item {
  padding: 5px 12px;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.menu-item[data-track="democracy"] {
  background-color: var(--democracy-color);
}

.menu-item[data-track="climate"] {
  background-color: var(--climate-color);
}

.menu-item[data-track="security"] {
  background-color: var(--security-color);
}

.menu-item[data-track="economy"] {
  background-color: var(--economy-color);
}

.menu-item.active {
  background-color: #ffffffbb;
}

.menu-item:hover {
  cursor: pointer;
  background-color: #ffffffbb;
  
}

.content {
  display: flex;
  justify-content: space-between;
  height: auto;
}

#map {
  width: 100%;
  height: 80vh;
  background-color: white;
  flex: 1;
  margin-right: 16px;
  border-radius: 5px; 
  box-sizing: border-box; 
}

.tooltip-link {
  color: white;  /* Change the link color to black */
  text-decoration: underline;  /* Remove the underline */
}

.tooltip-link:hover {
  color: grey;  /* Change the link color to blue when hovered */
  text-decoration: underline;  /* Add an underline when hovered */
}


/* CLEANED UP FROM HERE */

/* INFOBOX LAYOUT */ 

#countryDetails {
  width: 400px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  transition: max-height 0.3s ease-in-out;
  max-height: 100vh;
  border-radius: 5px;
}

#countryHeader {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  background-color: #f8f8f8; /* light grey */
  padding: 10px;
  border-radius: 5px; 
  box-sizing: border-box; 
  margin-bottom: 8px;
}

.county-facts {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.fact {
  display: flex;
  flex-direction: column;
  margin-bottom: 8px;
  background-color: #f8f8f8; /* light grey */
  padding: 8px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease; 
  border: solid 2px white;
}

.fact.non-clickable {
  cursor: default !important;
  margin-bottom: 0;
}


.fact:hover {
  background-color: #ffffff99 !important; 
}

.fact.non-clickable:hover {
  background-color: #f8f8f8 !important; 
}

.fact-grid {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  box-sizing: border-box;
}

.fact-column.democracy {
  width: 196px;
}

.fact-row {
  width: 400px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.fact-row > div[metric="emissions"],
.fact-row > div[metric="emissionsPerCapita"] {
  width: 200px;
}

.fact-row > div[metric="changeSince1990"],
.fact-row > div[metric="kgCO2PerDollar"] {
  width: 152px;
}

.fact-row > div[metric="alliance"],
.fact-row > div[metric="globalPeaceIndex"] {
  width: 152px;
}

.fact-row > div[metric="activeMilitary"],
.fact-row > div[metric="terrorismIndex"] {
  width: 200px;
}

div[metric="gdp"] {
  width: 114px;
}

div[metric="gdpPerCapita"]{
  width: 112px;
}

div[metric="gdpGrowth"] {
  width: 96px;
}

div[metric="unemploymentRate"],
div[metric="gini"] {
  width: 176px;
}

div[metric="lastInflation"],
div[metric="govDebt"] {
  width: 176px;
}

.fact-column.climate#climateCol1 {
  width: 220px;
}

.fact[metric="percentageRenewableElectricity"] {
  width: 152px;
  position: relative;
}

.fact-grid .fact-column:not(:last-child) {
  margin-right: 8px;
}

#factEffectiveParty {
  height: 70px;
}

#factVoterTurnout {
  height: 90px;
}

#factWomenInParliament {
  height: 188px;
}

svg#democracyIndex {
  width: 380px;
  height: 20px;
  pointer-events: none;
}

svg#effectivePartyBar {
  width: 176px;
  height: 30px;
  pointer-events: none;
}

svg#voterTurnoutBar {
  width: 176px;
  pointer-events: none;

}

svg#renewableElectricityChart {
  width: 152px;
  height: 92px;
  pointer-events: none;
  padding-top: 10px;
  overflow: visible;

}

svg#renewableElectricityChart {
    width: 152;
    height: 100px;
    pointer-events: none;
  }

#percentageRenewableElectricity {
  position: absolute;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}


/* Mess below */



.group-title {
  text-transform: uppercase;
  font-size: 0.9em;
  color: #666;
  margin-top: 20px;
  margin-bottom: 10px;
}

.quick-fact {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.quick-fact .fact-icon {
  margin-right: 10px;
}

.quick-fact .fact-details {
  flex-grow: 1;
}

#countryDetails.open {
  transform: translateX(0);
}

#countryInfo {
  margin-left: 20px;
  width: 100%;
}

.quick-facts {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  gap: 5px;
}

#countryName {
  font-size: 1.5em;
  font-weight: 700;
  cursor: pointer;
}





.fact-icon-label-container {
  display: inline-flex;
  align-items: center;
}

.fact-icon-label {
  font-size: 14px;
  color: #888;
  cursor: help;
}

.fact-icon {
  margin-right: 5px;
}

.fact-data {
  font-size: 24px;
  font-weight: bold;
}

.fact-extra {
  font-size: 18px;
}

.fact-icon {
  margin-right: 10px;
}

.fact-label {
  font-size: 14px;
  color: #888;
}

.fact-data {
  font-weight: bold;
}

.fact-details {
  position: relative;
}

.voter-turnout-text {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translate(-50%, -100%);
  text-align: center;
}

.women-in-parliament-text {
  position: absolute;
  top: 48%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.small-flag {
  width: 50px;
  height: 50px;
}

.tooltip-flag {
  width: 20px;
  height: 20px;
  margin-right: 5px;
}

.country-tooltip {
  position: absolute;
  background-color: var(--primary-color);
  border-radius: 4px;
  padding: 10px;
  pointer-events: none;
}

.country-tooltip::after {
  content: "";
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid var(--primary-color);
}

.tooltip-content {
  display: flex;
  align-items: center;
}

.country-tooltip-flag {
  width: 20px;
  height: 20px;
  margin-right: 5px;
}

.Country {
  cursor: pointer;
}

.democracy-index-container {
  display: flex;
  justify-content: space-between;
}

#democracyIndex {
  overflow: visible;
}

#democracyIndex line {
  stroke: lightgrey;
}

#democracyIndex path {
  stroke: lightgrey;
}

#democracyIndex text {
  fill: grey;
}

/*#democracyIndex {
    background-color: #4caf50;
    height: 20px;
    color: white;
    text-align: center;
    line-height: 20px;
    transition: width 0.2s ease;
}*/

#effectivePartyBar {
  width: 100%;
}

.footer {
  color: #222; /* Or any color that is legible on the footer background */
  font-size: 0.8em;
  max-width: 1600px;
  margin: 0 auto;
}

a {
  color: #222;
}

/* hover on a tag with animation */
a:hover {
  color: #555;
  transition: color 0.3s ease;
}

.scrollable-container {
  overflow-y: auto; /* This will create a scrollbar for the container */
  border-radius: 5px;
}

.context-container {
  hyphens: auto;
  padding-top: 5px;
  font-size: 0.95em;
}

#mobileWarning {
  display: none;

}

#mobileWarningContent {
  z-index: 1000000002;

}

#mobileBackground {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--primary-color);
  z-index: 1000000001;
  overflow: hidden;

}

#sorryText {
  font-family: "antique-olive-nord", sans-serif;
  text-transform: uppercase;
  font-size: 50px;
  margin-bottom: 20px;
}

@media screen and (max-width: 768px) {
  #mobileWarning {
    display: flex;
  }
}