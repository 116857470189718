:root {
  --primary-color: #ab8bf8;
  --democracy-color: #f8ab8b;
  --climate-color: #8bf8ab;
  --security-color: #8b8bf8;
  --economy-color: #f8f88b;
}

.track-details {
  opacity: 0;
  max-height: 0;
  overflow: hidden;
}

.track-details.active {
  opacity: 1;
  max-height: 1200px;
}

body {
  opacity: 0;
  height: auto;
  padding: 20px;
  font-family: degular, sans-serif;
  font-style: normal;
  font-weight: 400;
  transition: opacity .5s ease-in-out;
  overflow: hidden;
}

path {
  transition: fill .5s;
}

#container {
  max-width: 1600px;
  height: auto;
  margin: 0 auto;
}

.header {
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  display: flex;
}

.title {
  text-transform: uppercase;
  font-family: antique-olive-nord, sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
}

.menu {
  justify-content: space-between;
  width: 400px;
  display: flex;
}

.menu-item {
  border-radius: 5px;
  padding: 5px 12px;
  transition: background-color .3s;
}

.menu-item[data-track="democracy"] {
  background-color: var(--democracy-color);
}

.menu-item[data-track="climate"] {
  background-color: var(--climate-color);
}

.menu-item[data-track="security"] {
  background-color: var(--security-color);
}

.menu-item[data-track="economy"] {
  background-color: var(--economy-color);
}

.menu-item.active {
  background-color: #fffb;
}

.menu-item:hover {
  cursor: pointer;
  background-color: #fffb;
}

.content {
  justify-content: space-between;
  height: auto;
  display: flex;
}

#map {
  box-sizing: border-box;
  background-color: #fff;
  border-radius: 5px;
  flex: 1;
  width: 100%;
  height: 80vh;
  margin-right: 16px;
}

.tooltip-link {
  color: #fff;
  text-decoration: underline;
}

.tooltip-link:hover {
  color: gray;
  text-decoration: underline;
}

#countryDetails {
  border-radius: 5px;
  flex-direction: column;
  align-items: flex-start;
  width: 400px;
  max-height: 100vh;
  transition: max-height .3s ease-in-out;
  display: flex;
  overflow: hidden;
}

#countryHeader {
  box-sizing: border-box;
  background-color: #f8f8f8;
  border-radius: 5px;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin-bottom: 8px;
  padding: 10px;
  display: flex;
}

.county-facts {
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  display: flex;
}

.fact {
  cursor: pointer;
  background-color: #f8f8f8;
  border: 2px solid #fff;
  border-radius: 5px;
  flex-direction: column;
  margin-bottom: 8px;
  padding: 8px;
  transition: background-color .3s;
  display: flex;
}

.fact.non-clickable {
  margin-bottom: 0;
  cursor: default !important;
}

.fact:hover {
  background-color: #fff9 !important;
}

.fact.non-clickable:hover {
  background-color: #f8f8f8 !important;
}

.fact-grid {
  box-sizing: border-box;
  flex-flow: row;
  display: flex;
}

.fact-column.democracy {
  width: 196px;
}

.fact-row {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 400px;
  display: flex;
}

.fact-row > div[metric="emissions"], .fact-row > div[metric="emissionsPerCapita"] {
  width: 200px;
}

.fact-row > div[metric="changeSince1990"], .fact-row > div[metric="kgCO2PerDollar"], .fact-row > div[metric="alliance"], .fact-row > div[metric="globalPeaceIndex"] {
  width: 152px;
}

.fact-row > div[metric="activeMilitary"], .fact-row > div[metric="terrorismIndex"] {
  width: 200px;
}

div[metric="gdp"] {
  width: 114px;
}

div[metric="gdpPerCapita"] {
  width: 112px;
}

div[metric="gdpGrowth"] {
  width: 96px;
}

div[metric="unemploymentRate"], div[metric="gini"], div[metric="lastInflation"], div[metric="govDebt"] {
  width: 176px;
}

.fact-column.climate#climateCol1 {
  width: 220px;
}

.fact[metric="percentageRenewableElectricity"] {
  width: 152px;
  position: relative;
}

.fact-grid .fact-column:not(:last-child) {
  margin-right: 8px;
}

#factEffectiveParty {
  height: 70px;
}

#factVoterTurnout {
  height: 90px;
}

#factWomenInParliament {
  height: 188px;
}

svg#democracyIndex {
  pointer-events: none;
  width: 380px;
  height: 20px;
}

svg#effectivePartyBar {
  pointer-events: none;
  width: 176px;
  height: 30px;
}

svg#voterTurnoutBar {
  pointer-events: none;
  width: 176px;
}

svg#renewableElectricityChart {
  pointer-events: none;
  pointer-events: none;
  width: 152px;
  height: 100px;
  padding-top: 10px;
  overflow: visible;
}

#percentageRenewableElectricity {
  text-align: center;
  position: absolute;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.group-title {
  text-transform: uppercase;
  color: #666;
  margin-top: 20px;
  margin-bottom: 10px;
  font-size: .9em;
}

.quick-fact {
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  display: flex;
}

.quick-fact .fact-icon {
  margin-right: 10px;
}

.quick-fact .fact-details {
  flex-grow: 1;
}

#countryDetails.open {
  transform: translateX(0);
}

#countryInfo {
  width: 100%;
  margin-left: 20px;
}

.quick-facts {
  flex-direction: row;
  justify-content: space-between;
  gap: 5px;
  display: flex;
}

#countryName {
  cursor: pointer;
  font-size: 1.5em;
  font-weight: 700;
}

.fact-icon-label-container {
  align-items: center;
  display: inline-flex;
}

.fact-icon-label {
  color: #888;
  cursor: help;
  font-size: 14px;
}

.fact-data {
  font-size: 24px;
  font-weight: bold;
}

.fact-extra {
  font-size: 18px;
}

.fact-icon {
  margin-right: 10px;
}

.fact-label {
  color: #888;
  font-size: 14px;
}

.fact-data {
  font-weight: bold;
}

.fact-details {
  position: relative;
}

.voter-turnout-text {
  text-align: center;
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translate(-50%, -100%);
}

.women-in-parliament-text {
  text-align: center;
  position: absolute;
  top: 48%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.small-flag {
  width: 50px;
  height: 50px;
}

.tooltip-flag {
  width: 20px;
  height: 20px;
  margin-right: 5px;
}

.country-tooltip {
  background-color: var(--primary-color);
  pointer-events: none;
  border-radius: 4px;
  padding: 10px;
  position: absolute;
}

.country-tooltip:after {
  content: "";
  border-left: 10px solid #0000;
  border-right: 10px solid #0000;
  border-top: 10px solid var(--primary-color);
  width: 0;
  height: 0;
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
}

.tooltip-content {
  align-items: center;
  display: flex;
}

.country-tooltip-flag {
  width: 20px;
  height: 20px;
  margin-right: 5px;
}

.Country {
  cursor: pointer;
}

.democracy-index-container {
  justify-content: space-between;
  display: flex;
}

#democracyIndex {
  overflow: visible;
}

#democracyIndex line, #democracyIndex path {
  stroke: #d3d3d3;
}

#democracyIndex text {
  fill: gray;
}

#effectivePartyBar {
  width: 100%;
}

.footer {
  color: #222;
  max-width: 1600px;
  margin: 0 auto;
  font-size: .8em;
}

a {
  color: #222;
}

a:hover {
  color: #555;
  transition: color .3s;
}

.scrollable-container {
  border-radius: 5px;
  overflow-y: auto;
}

.context-container {
  hyphens: auto;
  padding-top: 5px;
  font-size: .95em;
}

#mobileWarning {
  display: none;
}

#mobileWarningContent {
  z-index: 1000000002;
}

#mobileBackground {
  background-color: var(--primary-color);
  z-index: 1000000001;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
}

#sorryText {
  text-transform: uppercase;
  margin-bottom: 20px;
  font-family: antique-olive-nord, sans-serif;
  font-size: 50px;
}

@media screen and (width <= 768px) {
  #mobileWarning {
    display: flex;
  }
}
/*# sourceMappingURL=index.04995d2f.css.map */
